<template>
  <layout-default v-if="group">
    <div class="container mt-5">
      <breadcrumb class="breadcrumb">
        <link-wrapper location="/" class="breadcrumb-text text-decoration-none">
          <span class="breadcrumb-home">Inicio</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
        <link-wrapper
          location="/direct-sale"
          class="breadcrumb-text text-decoration-none"
        >
          <span class="breadcrumb-home">Venta Directa</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
      </breadcrumb>
    </div>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-3 col-sm-12 pe-4">
          <div class="filter-search-shop text-align-center text-md-left">
            <div>
              <filter-title class="title mb-3 mt-0" :title="groupName" />
              <section class="mt-4">
                <h5 v-if="group.attributes.start_date">Fecha de Inicio:</h5>
                <p class="pt-2 pb-3" v-if="group.attributes.start_date">
                  {{ formatDate(group.attributes.start_date) }}
                </p>
                <h5 v-if="group.attributes.end_date">Fecha de Cierre:</h5>
                <p class="pt-2 pb-3" v-if="group.attributes.end_date">
                  {{ formatDate(group.attributes.end_date) }}
                </p>
                <h5 class="mb-3" v-if="group.attributes.bases">
                  Catálogo y Bases:
                </h5>
                <button-filled
                  size="small"
                  class="w-75 mx-auto mx-md-2"
                  :hasRoundedCorners="true"
                  :is-link="true"
                  :external="true"
                  target="_blank"
                  v-if="group.attributes.bases"
                  :location="group.attributes.bases"
                >
                  <font-awesome-icon :icon="['fas', 'file-pdf']" />
                  <span class="mx-2" style="font-size: 12px">
                    Descargar Catálogo y Bases
                  </span>
                </button-filled>

                <h5 class="mt-4">Descripción:</h5>
                <p class="pt-2 pb-3" v-html="group.attributes.description"></p>
              </section>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-sm-12 shop-grid" id="topGrid">
          <div class="fluid-container filter-bar-shop">
            <filter-bar
              :totalResults="numberOfItems"
              :itemsShowing="
                currentPage * itemsPerPage > numberOfItems
                  ? numberOfItems
                  : currentPage * itemsPerPage
              "
            >
              <template v-slot:left>
                <change-layout-buttons
                  class="me-4"
                  @onChange="layout = !layout"
                ></change-layout-buttons>
              </template>
            </filter-bar>
          </div>
          <keep-alive>
            <component
              :is="layout === true ? 'Grid' : 'List'"
              :items="productsPerPage()"
              :itemsPerRow="3"
            />
          </keep-alive>

          <pagination-buttons
            class="pagination-shop mx-auto"
            v-if="numberOfItems / itemsPerPage > 1"
            resetID="topGrid"
            :pageNumbers="Math.ceil(numberOfItems / itemsPerPage)"
            @onChangePageNoRequest="updatePage"
            :currentPage="
              $route.params.page === undefined
                ? currentPage
                : parseInt($route.params.page)
            "
          ></pagination-buttons>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import PaginationButtons from "@/components/atoms/PaginationButtons/PaginationButtons.vue";
import FilterBar from "@/components/atoms/Filter/FilterBar.vue";
import Grid from "@/components/molecules/Layout/Grid.vue";
import List from "@/components/molecules/Layout/List.vue";
import ChangeLayoutButtons from "@/components/atoms/Button/ButtonDouble.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Card from "@/components/molecules/Card/Card.vue";
import FilterTitle from "@/components/atoms/Filter/FilterTitle.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";
import moment from "moment";

export default {
  async mounted() {
    this.scrollProductIfParamLot(this.$route.params.number);
    await this.fetchGroup({
      id: this.id,
    });
    await this.getProducts();
    document.title = this.group?.attributes?.name || "Grupo";
    this.$store.commit("setLocation", [
      {
        title: this.group?.attributes?.name || "",
        location: `/direct-sale/group/${this.$route.params.id}`,
      },
    ]);
  },
  components: {
    LayoutDefault,
    PaginationButtons,
    FilterBar,
    Grid,
    List,
    ChangeLayoutButtons,
    Breadcrumb,
    Card,
    ButtonFilled,
    FilterTitle,
    LinkWrapper,
  },
  data() {
    return {
      id: this.$route.params.id,
      itemsPerPage: 9,
      currentPage: 1,
      test: "",
      selectedValue: "",
      layout: true,
      numberOfItems: 0,
    };
  },
  methods: {
    ...mapActions({
      fetchGroup: "direct_sale/fetchGroup",
      fetchProducts: "direct_sale/fetchProducts",
    }),
    async getProducts(page = null) {
      if (!page) {
        this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
      }
      const { meta } = await this.fetchProducts({
        group_id: this.id,
        page: this.currentPage,
        per_page: this.itemsPerPage,
      });
      this.numberOfItems = meta.total;
    },
    productsPerPage() {
      if (this.products.data.length) {
        return this.products.data;
      }
      return [];
    },
    updatePage(pageNo) {
      this.currentPage = pageNo;
      this.getProducts(pageNo);
    },
    // Movel scroll de la pagina al lote en curso si se
    // presiono el boton de regresar desde show de producto
    scrollProductIfParamLot(numberLot) {
      if (numberLot) {
        const element = document.getElementById(numberLot);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapGetters({
      group: "direct_sale/group",
      products: "direct_sale/products",
    }),
    groupName() {
      return this.group?.attributes?.name || "Cargando..";
    },
  },
};
</script>
<style scoped="scoped"></style>
